import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { FeatureTypes } from "src/features/account/types";
import SpeakerRecognitionSpeakersNumberModal from "src/features/speakerRecognition/modal/SpeakerRecognitionSpeakersNumberModal";
import { useAppDispatch } from "src/app/store/hooks";
import { showSpeakerRecognitionSpeakersNumberModal } from "src/features/speakerRecognition/speakerRecognitionSlice";
import { OverlayTrigger, Tooltip } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
import useAccount from "src/features/account/hooks/useAccount";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectConversationFileIds } from "src/features/conversations/conversationsSlice";
import { selectAllTranscripts } from "src/features/transcripts/transcriptsSlice";
const SpeakerRecognition = () => {
    var _a;
    const { callId } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // @ts-ignore
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    // @ts-ignore
    const transcripts = useSelector((state) => selectAllTranscripts(state, { fileId: fileIds }));
    const { isFeatureEnabled, upgradeAccount } = useAccount();
    const speakerRecognitionFeatureEnabled = isFeatureEnabled(FeatureTypes.SpeakerRecognition);
    const handleClick = () => {
        if (!speakerRecognitionFeatureEnabled) {
            return upgradeAccount(AccountUpgradeModalTypeEnum.SpeakersCount);
        }
        dispatch(showSpeakerRecognitionSpeakersNumberModal());
    };
    if (!((_a = transcripts[0]) === null || _a === void 0 ? void 0 : _a.transcript.length)) {
        return;
    }
    return (_jsxs(_Fragment, { children: [_jsx(SpeakerRecognitionSpeakersNumberModal, {}), _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "number-of-speakers", children: t("Update.number.of.speakers_") }), children: _jsx("span", { children: _jsx(MdOutlinePeopleAlt, { size: 20, onClick: handleClick }) }) })] }));
};
export default SpeakerRecognition;
