export var RoleTypes;
(function (RoleTypes) {
    RoleTypes["User"] = "user";
    RoleTypes["Admin"] = "admin";
    RoleTypes["Reseller"] = "reseller";
})(RoleTypes || (RoleTypes = {}));
export var SubscriptionPlanTypes;
(function (SubscriptionPlanTypes) {
    SubscriptionPlanTypes["Free"] = "free";
    SubscriptionPlanTypes["DemoPaid"] = "demoPaid";
    SubscriptionPlanTypes["Premium"] = "premium";
    SubscriptionPlanTypes["PremiumYearly"] = "premiumYear";
})(SubscriptionPlanTypes || (SubscriptionPlanTypes = {}));
export var FeatureTypes;
(function (FeatureTypes) {
    FeatureTypes["TranscriptSelect"] = "transcriptSelect";
    FeatureTypes["TranscriptDownload"] = "transcriptDownload";
    FeatureTypes["RecordingDownload"] = "recordingDownload";
    FeatureTypes["ThirdPartyApi"] = "thirdPartyApi";
    FeatureTypes["TranscriptSummary"] = "transcriptSummary";
    FeatureTypes["IntegrationsCrm"] = "integrationsCrm";
    FeatureTypes["PrivacyMode"] = "privacyMode";
    FeatureTypes["AiAnalysis"] = "aiAnalysis";
    FeatureTypes["GoogleDriveIntegration"] = "driveIntegration";
    FeatureTypes["SpeakerRecognition"] = "speakerRecognition";
})(FeatureTypes || (FeatureTypes = {}));
