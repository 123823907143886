var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apiApp from "src/app/services/apiApp";
import { updateProfilePlan } from "src/features/auth/store/actions/profile";
import { enableAutoIntegration } from "src/features/integrations/integrationsSlice";
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getSubscriptions: builder.query({
            query: () => "/limiter/subscriptions/current",
            providesTags: ["Subscriptions"]
        }),
        newsletterSubscribe: builder.mutation({
            query: (body) => ({
                url: `/newsletter/authenticated`,
                method: "POST",
                body
            }),
        }),
        //export interface GetActivitiesRequest {
        // 	start: number
        // }
        updateAccount: builder.mutation({
            query: (plan) => ({
                url: `/limiter/subscriptions`,
                method: "POST",
                body: { plan, cancelExisting: true }
            }),
            onQueryStarted(arg, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const plan = arg;
                    try {
                        const { data } = yield queryFulfilled;
                        const payload = {
                            plan,
                            features: data.features,
                            trialEndDate: data.trialEndDate
                        };
                        dispatch(updateProfilePlan(payload));
                        dispatch(enableAutoIntegration());
                    }
                    catch (_a) { }
                });
            }
        }),
        validateDiscountCode: builder.mutation({
            query: ({ discountCode, reCaptchaKey }) => ({
                url: `/auth/discountCode/validate/${discountCode}`,
                method: "POST",
                body: { reCaptchaKey }
            }),
        }),
    })
});
export const { useNewsletterSubscribeMutation, useUpdateAccountMutation, useValidateDiscountCodeMutation, useGetSubscriptionsQuery, } = extendedApi;
