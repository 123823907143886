import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BifIconDownload, Dropdown, Stack } from "banafo-design-system";
import { selectRecordingDetailsByCallId, selectRecordingDetailsStreamsById } from "src/features/auth/store/selectors/recordingDetails";
import { selectPlaybackFileFormat } from "src/features/auth/store/selectors/playback";
import { startAppLoader, stopAppLoader } from "src/features/auth/store/actions/app";
import { loadMediaRecording } from "src/features/auth/store/actions/recordingDetails";
import { downloadTranscripts } from "src/features/auth/store/actions/transcripts";
import { showDownloadTranscriptsModal } from "src/features/conversations/conversationsSlice";
import { TRANSCRIPT_DOWNLOAD_FORMATS_ENUM, TRANSCRIPT_JSON_FORMAT, TRANSCRIPT_PLAIN_FORMAT } from "src/features/transcripts/config";
import { MIMETYPE_MAP } from "src/features/conversations/config";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
const AudioDownloadAction = ({ nonAudioOnly }) => {
    const { callId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const recording = useSelector((state) => selectRecordingDetailsByCallId(state)(callId));
    const streams = useSelector((state) => selectRecordingDetailsStreamsById(state)(recording === null || recording === void 0 ? void 0 : recording.id));
    const fileFormat = useSelector(selectPlaybackFileFormat);
    const { upgradeAccount, isFeatureEnabled } = useAccount();
    const transcriptDownloadEnabled = isFeatureEnabled(FeatureTypes.TranscriptDownload);
    const recordingDownloadEnabled = isFeatureEnabled(FeatureTypes.RecordingDownload);
    const handleDownloadRecording = (fileId) => {
        if (recordingDownloadEnabled) {
            dispatch(startAppLoader());
            dispatch(loadMediaRecording(fileId))
                .then(response => {
                const url = window.URL.createObjectURL(response.body);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileId}.${MIMETYPE_MAP[fileFormat]}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
                .finally(() => {
                dispatch(stopAppLoader());
            });
        }
        else {
            upgradeAccount(AccountUpgradeModalTypeEnum.General);
        }
    };
    const handleDownloadTranscript = (value) => () => {
        if (value !== "") {
            if (transcriptDownloadEnabled) {
                if ([TRANSCRIPT_PLAIN_FORMAT, TRANSCRIPT_JSON_FORMAT].includes(value)) {
                    dispatch(showDownloadTranscriptsModal({ format: value }));
                    return;
                }
                dispatch(downloadTranscripts(streams.map(stream => stream.fileId).join(','), value))
                    .catch((err) => err)
                    .finally(() => {
                    stopAppLoader();
                });
            }
            else {
                upgradeAccount(AccountUpgradeModalTypeEnum.General);
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dropdown.ItemText, { className: "text-center fw-bold", children: t('Download_') }), _jsx(Dropdown.Divider, {}), !nonAudioOnly && streams.map((stream, source) => (_jsx(Dropdown.Item, { onClick: () => handleDownloadRecording(stream.fileId), children: _jsxs(Stack, { direction: "horizontal", className: "justify-content-between", children: [_jsxs("span", { className: "text-capitalize", children: [t('Audio_'), " ", stream.origin] }), _jsx(BifIconDownload, {})] }) }, source))), TRANSCRIPT_DOWNLOAD_FORMATS_ENUM.map(({ value, text }) => (_jsx(Dropdown.Item, { onClick: handleDownloadTranscript(value), children: _jsxs(Stack, { direction: "horizontal", className: "justify-content-between", children: [_jsx("span", { className: "text-capitalize", children: text }), _jsx(BifIconDownload, {})] }) }, value)))] }));
};
export default AudioDownloadAction;
