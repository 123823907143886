var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import apiApp from "src/app/services/apiApp";
import { selectIsBrowserExtensionAvailable } from "src/features/browserExtension/browserExtensionSlice";
import { PROVIDER_TYPE_BROWSER, PROVIDER_TYPE_WEB_CONFERENCE } from "./config";
import { getDetectedBrowserName } from "src/features/browserExtension/utils/browserDetect";
import { RootState } from "src/app/store/store";
const providersAdapter = createEntityAdapter();
const initialProvidersState = providersAdapter.getInitialState();
const integrationsAdapter = createEntityAdapter();
const initialIntegrationsState = integrationsAdapter.getInitialState();
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getProviders: builder.query({
            query: () => "/providers/auth",
            transformResponse: ({ list = [] }) => providersAdapter.setAll(initialProvidersState, list.map((_a) => {
                var { name } = _a, item = __rest(_a, ["name"]);
                return (Object.assign({ id: name, name }, item));
            }))
        }),
        getIntegrations: builder.query({
            query: () => "/integrations",
            transformResponse: ({ list = [] }) => integrationsAdapter.setAll(initialIntegrationsState, list.map((_a) => {
                var { providerName } = _a, item = __rest(_a, ["providerName"]);
                return (Object.assign({ id: providerName, providerName }, item));
            })),
            providesTags: ["Integrations"]
        }),
        addIntegration: builder.mutation({
            query(body) {
                return {
                    url: `/integrations`,
                    method: "POST",
                    body
                };
            },
        }),
        removeIntegration: builder.mutation({
            query(provider) {
                return {
                    url: `/integrations/${provider}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Integrations"]
        }),
    })
});
export const { useGetProvidersQuery, useGetIntegrationsQuery, useAddIntegrationMutation, useRemoveIntegrationMutation, } = apiApp;
//SELECTORS
//Providers
export const selectProvidersResult = extendedApi.endpoints.getProviders.select();
export const selectProvidersData = createSelector(selectProvidersResult, providersResult => providersResult.data);
export const { selectAll: selectAllProviders, selectEntities: selectEntitiesProviders, selectById: selectProviderById } = providersAdapter.getSelectors(state => { var _a; return (_a = selectProvidersData(state)) !== null && _a !== void 0 ? _a : initialProvidersState; });
//Integrations
export const selectIntegrationsResult = extendedApi.endpoints.getIntegrations.select();
export const selectIntegrationsData = createSelector(selectIntegrationsResult, integrationsResult => integrationsResult.data);
export const { selectAll: selectAllIntegrations, selectEntities: selectEntitiesIntegrations, selectById: selectIntegrationById } = providersAdapter.getSelectors(state => { var _a; return (_a = selectIntegrationsData(state)) !== null && _a !== void 0 ? _a : initialIntegrationsState; });
//SLICE
export const integrationsSlice = createSlice({
    name: 'integrations',
    initialState: {
        filters: {
            text: '',
            status: '',
            type: ''
        },
        connectingProviderId: '',
        autoIntegration: false,
    },
    reducers: {
        setTextFilter: (state, action) => {
            state.filters.text = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.filters.status = action.payload;
        },
        setTypeFilter: (state, action) => {
            state.filters.type = action.payload;
        },
        setConnectingProviderId: (state, action) => {
            state.connectingProviderId = action.payload;
        },
        resetConnectingProviderId: (state) => {
            state.connectingProviderId = '';
        },
        enableAutoIntegration: (state) => {
            state.autoIntegration = true;
        },
        disableAutoIntegration: (state) => {
            state.autoIntegration = false;
        },
    }
});
export const { name, actions } = integrationsSlice;
export const { setTextFilter, setStatusFilter, setTypeFilter, setConnectingProviderId, resetConnectingProviderId, enableAutoIntegration, disableAutoIntegration, } = integrationsSlice.actions;
export default integrationsSlice.reducer;
export const selectIntegrationsFilters = state => state[name].filters;
export const selectConnectingProviderId = state => state[name].connectingProviderId;
export const selectAutoIntegration = state => state[name].autoIntegration;
//SELECTORS
export const selectIntegratedProviders = (state) => {
    let providers = selectAllProviders(state);
    let integrations = selectAllIntegrations(state);
    const isBrowserExtensionAvailable = selectIsBrowserExtensionAvailable(state);
    const clientBrowserName = getDetectedBrowserName();
    const isProviderIntegrated = (provider, integrations) => {
        return Boolean(integrations.find(integration => integration.status === 'active'
            && integration.providerName === provider.name));
    };
    const isProviderVirtuallyIntegrated = ({ type, name }) => {
        return ((type === PROVIDER_TYPE_WEB_CONFERENCE && isBrowserExtensionAvailable)
            || (type === PROVIDER_TYPE_BROWSER && isBrowserExtensionAvailable && clientBrowserName === name));
    };
    const isActive = (provider) => {
        return isProviderIntegrated(provider, integrations) || isProviderVirtuallyIntegrated(provider);
    };
    const { text: textFilter, status: statusFilter, type: typeFilter } = selectIntegrationsFilters(state);
    let integratedProviders = providers
        .map(provider => (Object.assign(Object.assign({}, provider), { activating: false, isActive: isActive(provider), isVirtuallyActive: isProviderVirtuallyIntegrated(provider) })))
        .filter((provider) => textFilter ? provider.name.includes(textFilter) : provider)
        .filter((provider) => statusFilter ? (statusFilter === 'active' ? (provider.isActive === true) : provider.isActive === false) : provider)
        .filter((provider) => typeFilter ? (typeFilter === provider.type) : provider);
    return integratedProviders;
};
export const selectConnectingProvider = (state) => {
    const connectingProviderId = selectConnectingProviderId(state);
    if (!connectingProviderId) {
        return {};
    }
    const connectingProvider = selectProviderById(state, connectingProviderId);
    return connectingProvider;
};
export const selectActiveIntegrations = createSelector(selectAllIntegrations, (integrations) => integrations.filter(integration => integration.status === 'active'));
export const selectIsIntegrationActive = createSelector(selectActiveIntegrations, (_state, integration) => integration, (integrations, integrationId) => !!integrations.find(integration => integration.id === integrationId));
export const selectActiveContactsIntegrations = (state) => {
    let integrations = selectAllIntegrations(state);
    return integrations
        .filter(integration => { var _a; return integration.status === 'active' && ((_a = integration.contactTypes) === null || _a === void 0 ? void 0 : _a.includes('contact')); });
};
export const selectHasActiveContactsIntegrations = (state) => {
    return selectActiveContactsIntegrations(state).length > 0;
};
