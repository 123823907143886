import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BanafoModal, Button } from "banafo-design-system";
import { useAccountUpdateModal } from "src/features/accountUpgrade/hooks/useAccountUpdateModal";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
const GeneralAccountUpgradeModal = () => {
    const { t } = useTranslation();
    const { show, hideModal, upgradeAccount } = useAccountUpdateModal(AccountUpgradeModalTypeEnum.General);
    return (_jsxs(BanafoModal, { show: show, onHide: hideModal, footer: _jsx(Button, { variant: 'primary', onClick: upgradeAccount, className: 'text-uppercase text-white', children: t('Upgrade.to.PRO_') }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: t('Account.upgrade_') }), _jsx("p", { children: t('infos:Api.key.upgrade.page.paragraph.plan_') })] }));
};
export default GeneralAccountUpgradeModal;
