import apiApp from "src/app/services/apiApp";
import { createSlice } from "@reduxjs/toolkit";
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        addNumberOfSpeakers: builder.mutation({
            query: (body) => {
                return {
                    url: `/media/numberOfSpeakers`,
                    method: "POST",
                    body
                };
            },
            invalidatesTags: ['Transcripts']
        }),
    })
});
export const { useAddNumberOfSpeakersMutation, } = extendedApi;
//SLICE
export const speakerRecognitionSlice = createSlice({
    name: 'speakerRecognition',
    initialState: {
        speakerRecognitionSpeakersNumberModal: false,
    },
    reducers: {
        showSpeakerRecognitionSpeakersNumberModal: state => {
            state.speakerRecognitionSpeakersNumberModal = true;
        },
        hideSpeakerRecognitionSpeakersNumberModal: state => {
            state.speakerRecognitionSpeakersNumberModal = false;
        },
    }
});
export const { name, actions } = speakerRecognitionSlice;
export const { showSpeakerRecognitionSpeakersNumberModal, hideSpeakerRecognitionSpeakersNumberModal } = speakerRecognitionSlice.actions;
export default speakerRecognitionSlice.reducer;
export const selectSpeakerRecognitionSpeakersNumberModalIsOpen = (state) => state[name].speakerRecognitionSpeakersNumberModal;
