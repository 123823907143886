import apiApp from "src/app/services/apiApp";
import { createSlice } from "@reduxjs/toolkit";
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        uploadToGoogleDrive: builder.mutation({
            query: ({ fileIds }) => {
                const payload = { fileIds };
                const params = new URLSearchParams(payload);
                return {
                    url: `/transcripts/google-doc?${params}`,
                    method: "POST",
                };
            },
            invalidatesTags: ['Transcripts']
        }),
    })
});
export const { useUploadToGoogleDriveMutation, } = extendedApi;
//SLICE
export const googleDriveSlice = createSlice({
    name: 'googleDrive',
    initialState: {
        googleDriveInstructionsModal: false,
    },
    reducers: {
        showGoogleDriveInstructionsModal: state => {
            state.googleDriveInstructionsModal = true;
        },
        hideGoogleDriveInstructionsModal: state => {
            state.googleDriveInstructionsModal = false;
        },
    }
});
export const { name, actions } = googleDriveSlice;
export const { showGoogleDriveInstructionsModal, hideGoogleDriveInstructionsModal } = googleDriveSlice.actions;
export default googleDriveSlice.reducer;
export const selectGoogleDriveInstructionsModalIsOpen = (state) => state[name].googleDriveInstructionsModal;
