import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import { BanafoModal, Button } from "banafo-design-system";
import { useAccountUpdateModal } from "src/features/accountUpgrade/hooks/useAccountUpdateModal";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
const SpeakersAccountUpgradeModal = () => {
    const { t } = useTranslation();
    const { show, hideModal, upgradeAccount } = useAccountUpdateModal(AccountUpgradeModalTypeEnum.SpeakersCount);
    return (_jsxs(BanafoModal, { show: show, onHide: hideModal, footer: _jsx(Button, { variant: 'primary', onClick: upgradeAccount, className: 'text-uppercase text-white', children: t('Upgrade.to.PRO_') }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: t('Account.upgrade_') }), _jsx("p", { children: _jsx(Trans, { i18nKey: "infos:Speaker.recognition_" }) })] }));
};
export default SpeakersAccountUpgradeModal;
