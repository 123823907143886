import { useAppSelector } from "src/app/store/hooks";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
import useUpdateAccountFlow from "src/features/accountUpgrade/hooks/useUpdateAccountFlow";
const useUpgradeAccount = (isFree) => {
    const { reseller } = useAppSelector(selectAuthUser);
    const { showUpdateAccountModal, redirectToPaymentPage } = useUpdateAccountFlow();
    return (infoModal) => {
        if (reseller) {
            showUpdateAccountModal(AccountUpgradeModalTypeEnum.Reseller);
            return;
        }
        if (isFree && infoModal) {
            showUpdateAccountModal(infoModal);
        }
        else {
            redirectToPaymentPage();
        }
    };
};
export default useUpgradeAccount;
