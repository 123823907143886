import { createSlice } from "@reduxjs/toolkit";
import apiApp from "src/app/services/apiApp";
const accountInitialState = {
    unsubscribeModal: {
        isOpen: false
    },
};
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        unSubscribe: builder.mutation({
            query: (body) => ({
                url: `/limiter/unsubscribe`,
                method: "POST",
                body
            }),
        }),
    })
});
export const accountSlice = createSlice({
    name: 'account',
    initialState: accountInitialState,
    reducers: {
        showUnsubscribeModal: (state) => {
            state.unsubscribeModal.isOpen = true;
        },
        hideUnsubscribeModal: state => {
            state.unsubscribeModal.isOpen = false;
        },
    }
});
export const { useUnSubscribeMutation } = extendedApi;
export const { name, actions } = accountSlice;
export const { showUnsubscribeModal, hideUnsubscribeModal } = accountSlice.actions;
export default accountSlice.reducer;
export const selectActiveSubscriptionId = (state) => state[name].unsubscribeModal.activeSubscriptionId;
export const selectUnsubscribeModalIsOpen = (state) => state[name].unsubscribeModal.isOpen;
