import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "banafo-design-system";
import { getBrowserUrlByName, getDetectedBrowserUrl } from "src/features/browserExtension/utils/browserDetect";
import { PROVIDER_AUTH_STRATEGY_API_KEY, PROVIDER_AUTH_STRATEGY_API_NONE, PROVIDER_AUTH_STRATEGY_API_SERVICE_ACCOUNT, PROVIDER_STATUS_ACTIVE, PROVIDER_STATUS_COMING_SOON, PROVIDER_TYPE_BROWSER } from "./config";
import { selectAutoIntegration, selectConnectingProviderId, setConnectingProviderId, useAddIntegrationMutation, useRemoveIntegrationMutation } from "./integrationsSlice";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
const ProviderItemActivationButton = ({ provider: { name, title, type, description, status, authStrategy, activating, isActive, isVirtuallyActive, } = {}, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const connectBtn = useRef();
    const { isFeatureEnabled, upgradeAccount } = useAccount();
    const autoIntegration = useSelector((state) => selectAutoIntegration(state));
    const connectingProviderId = useSelector((state) => selectConnectingProviderId(state));
    const [addIntegration] = useAddIntegrationMutation({ fixedCacheKey: "add-integration" });
    const [removeIntegration] = useRemoveIntegrationMutation({ fixedCacheKey: "remove-integration" });
    useEffect(() => {
        var _a;
        if (autoIntegration && connectingProviderId === name) {
            (_a = connectBtn.current) === null || _a === void 0 ? void 0 : _a.click();
        }
    }, [autoIntegration, connectingProviderId]);
    const handleRemoveActivation = () => {
        removeIntegration(name);
    };
    const handleAddIntegration = () => {
        if (status === PROVIDER_STATUS_COMING_SOON) {
            dispatch(setConnectingProviderId(name));
            return;
        }
        if (status === PROVIDER_STATUS_ACTIVE) {
            if (!isFeatureEnabled(FeatureTypes.IntegrationsCrm)) {
                dispatch(setConnectingProviderId(name));
                upgradeAccount(AccountUpgradeModalTypeEnum.Integrations);
                return;
            }
        }
        if (authStrategy === PROVIDER_AUTH_STRATEGY_API_NONE) {
            if (type === PROVIDER_TYPE_BROWSER) {
                window.open(getBrowserUrlByName(name), "_blank");
            }
            else {
                window.open(getDetectedBrowserUrl(), "_blank");
            }
            return;
        }
        if (authStrategy === PROVIDER_AUTH_STRATEGY_API_KEY || authStrategy === PROVIDER_AUTH_STRATEGY_API_SERVICE_ACCOUNT) {
            dispatch(setConnectingProviderId(name));
            return;
        }
        addIntegration({ provider: name })
            .unwrap()
            .then(({ url }) => window.location = url)
            .catch(e => null);
    };
    if (isVirtuallyActive) {
        return;
    }
    if (activating) {
        return _jsx(Button, { variant: "dark", children: t('Connecting_') });
    }
    if (isActive) {
        return (_jsx(Button, { variant: "dark", onClick: handleRemoveActivation, children: t('Disconnect_') }));
    }
    if (!isActive) {
        return (_jsx(Button, { variant: 'success', ref: connectBtn, onClick: handleAddIntegration, children: t('Connect_') }));
    }
};
export default ProviderItemActivationButton;
