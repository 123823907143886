import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import useUpdateAccountFlow from "src/features/accountUpgrade/hooks/useUpdateAccountFlow";
import { hideAccountUpgradeModal, selectAccountUpgradeModal } from "src/features/accountUpgrade/accountUpgradeSlice";
export const useAccountUpdateModal = (type) => {
    const dispatch = useAppDispatch();
    const { isOpen, modalType } = useAppSelector(selectAccountUpgradeModal);
    const { redirectToPaymentPage } = useUpdateAccountFlow();
    const hideModal = () => {
        dispatch(hideAccountUpgradeModal());
    };
    const upgradeAccount = () => {
        redirectToPaymentPage();
        dispatch(hideAccountUpgradeModal());
    };
    const show = isOpen && modalType === type;
    return {
        show,
        hideModal,
        upgradeAccount
    };
};
