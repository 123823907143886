import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOutlet } from "react-router-dom";
import { Layout } from "banafo-design-system";
import AuthHeader from "src/features/layout/pageLayout/header/AuthHeader";
import Sidebar from "src/features/sidebar/Sidebar";
import OffcanvasSidebar from "src/features/sidebar/OffcanvasSidebar";
import GeneralLoader from "src/features/layout/pageLayout/GeneralLoader";
import ProfileModal from "src/features/account/modals/ProfileModal";
import ChangePasswordModal from "src/features/account/modals/ChangePasswordModal";
import ToastMessagesContainer from "src/features/toasts/ToastMessagesContainer";
import { useIsMobile } from "src/common/hooks/useIsMobile";
import "./AuthSidebarLayout.scss";
import ResellerSidebar from "src/features/sidebar/ResellerSidebar";
import useHasRole from "src/features/account/hooks/useHasRole";
import { RoleTypes } from "src/features/account/types";
import GeneralAccountUpgradeModal from "src/features/accountUpgrade/modals/GeneralAccountUpgradeModal";
import ResellerAccountUpgradeModal from "src/features/accountUpgrade/modals/ResellerAccountUpgradeModal";
const AuthSidebarLayout = ({ asideRight }) => {
    const outlet = useOutlet();
    const isMobile = useIsMobile();
    const scrollBar = isMobile ? 1 : 0;
    const isReseller = useHasRole(RoleTypes.Reseller);
    return (_jsxs(_Fragment, { children: [_jsxs(Layout, { isParent: true, className: "auth-sidebar-layout", header: _jsx(AuthHeader, {}), asideLeft: isReseller ? _jsx(ResellerSidebar, { className: "d-none d-lg-flex" }) : _jsx(Sidebar, { className: "d-none d-lg-flex" }), asideRight: asideRight, scrollBar: scrollBar, options: {
                    contentProps: { className: "position-relative" },
                    asideRightProps: { className: "feeds-container" }
                }, children: [_jsx(ToastMessagesContainer, {}), outlet] }), _jsx(OffcanvasSidebar, { isReseller: isReseller }), _jsx(GeneralLoader, {}), _jsx(ProfileModal, {}), _jsx(ChangePasswordModal, {}), _jsx(GeneralAccountUpgradeModal, {}), _jsx(ResellerAccountUpgradeModal, {})] }));
};
export default AuthSidebarLayout;
