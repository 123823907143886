import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BanafoModal, Button } from "banafo-design-system";
import { useAccountUpdateModal } from "src/features/accountUpgrade/hooks/useAccountUpdateModal";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
import { List, Text } from "@mantine/core";
import { useAppSelector } from "src/app/store/hooks";
import { selectAuthUser } from "src/features/auth/store/selectors/auth";
const ResellerAccountUpgradeModal = () => {
    const { t } = useTranslation();
    const { show, hideModal } = useAccountUpdateModal(AccountUpgradeModalTypeEnum.Reseller);
    const { reseller } = useAppSelector(selectAuthUser);
    if (!reseller) {
        return null;
    }
    return (_jsxs(BanafoModal, { show: show, onHide: hideModal, footer: _jsx(Button, { variant: 'primary', as: "a", href: `mailto:${reseller.email}`, className: 'text-uppercase text-white', children: t('Contact.Reseller_') }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: t('Account.upgrade_') }), _jsx("p", { children: t('infos:Upgrade.to.pro.reseller_') }), _jsxs(List, { listStyleType: "none", children: [_jsxs(List.Item, { children: [t('Contact.information_'), ":"] }), _jsx(List.Item, { children: _jsxs(List, { withPadding: true, listStyleType: "disc", children: [_jsxs(List.Item, { children: [t('Name_'), ": ", reseller === null || reseller === void 0 ? void 0 : reseller.name] }), _jsxs(List.Item, { children: [t('Email_'), ": ", _jsx(Text, { span: true, fw: "bold", children: reseller === null || reseller === void 0 ? void 0 : reseller.email })] }), _jsxs(List.Item, { children: [t('Address_'), ": ", reseller === null || reseller === void 0 ? void 0 : reseller.address] }), _jsxs(List.Item, { children: [t('Website_'), ": ", reseller === null || reseller === void 0 ? void 0 : reseller.website] })] }) })] })] }));
};
export default ResellerAccountUpgradeModal;
