import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BILLING_UPGRADE_ROUTE } from "src/app/router/config";
import { UPGRADE_PLAN } from "src/features/account/config";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
import { showAccountUpgradeModal } from "src/features/accountUpgrade/accountUpgradeSlice";
const useUpdateAccountFlow = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redirectToPaymentPage = () => {
        navigate(generatePath(BILLING_UPGRADE_ROUTE, { plan: UPGRADE_PLAN, upgrade: 'upgrade' }));
    };
    const showUpdateAccountModal = (type = AccountUpgradeModalTypeEnum.General) => {
        dispatch(showAccountUpgradeModal({ type }));
    };
    return {
        redirectToPaymentPage,
        showUpdateAccountModal
    };
};
export default useUpdateAccountFlow;
