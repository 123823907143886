var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apiApp from "src/app/services/apiApp";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
import { createSlice } from "@reduxjs/toolkit";
import { updateProfilePlan } from "src/features/auth/store/actions/profile";
import { enableAutoIntegration } from "src/features/integrations/integrationsSlice";
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        updateAccount: builder.mutation({
            query: (plan) => ({
                url: `/limiter/subscriptions`,
                method: "POST",
                body: { plan, cancelExisting: true }
            }),
            onQueryStarted(arg, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const plan = arg;
                    try {
                        const { data } = yield queryFulfilled;
                        const payload = {
                            plan,
                            features: data.features,
                            trialEndDate: data.trialEndDate
                        };
                        dispatch(updateProfilePlan(payload));
                        dispatch(enableAutoIntegration());
                    }
                    catch (_a) { }
                });
            }
        }),
    })
});
export const { useUpdateAccountMutation, } = extendedApi;
const initialSliceState = {
    accountUpgradeModalOpen: false,
    modalType: AccountUpgradeModalTypeEnum.General,
};
export const accountUpgradeSlice = createSlice({
    name: 'accountUpgrade',
    initialState: initialSliceState,
    reducers: {
        showAccountUpgradeModal: (state, action) => {
            const { type = AccountUpgradeModalTypeEnum.General } = action.payload || {};
            state.accountUpgradeModalOpen = true;
            state.modalType = type;
        },
        hideAccountUpgradeModal: state => {
            state.accountUpgradeModalOpen = false;
            state.modalType = AccountUpgradeModalTypeEnum.General;
        },
    }
});
export const { name, actions } = accountUpgradeSlice;
export const { showAccountUpgradeModal, hideAccountUpgradeModal, } = accountUpgradeSlice.actions;
export default accountUpgradeSlice.reducer;
export const selectAccountUpgradeModal = (state) => ({ isOpen: state[name].accountUpgradeModalOpen, modalType: state[name].modalType });
