import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BanafoModal, Button } from "banafo-design-system";
import { useSelector } from "react-redux";
import { resetConnectingProviderId, selectConnectingProvider } from "src/features/integrations/integrationsSlice";
import { Image } from "@mantine/core";
import { useAppDispatch } from "src/app/store/hooks";
import { useAccountUpdateModal } from "src/features/accountUpgrade/hooks/useAccountUpdateModal";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
const IntegrationsAccountUpgradeModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { show, hideModal, upgradeAccount } = useAccountUpdateModal(AccountUpgradeModalTypeEnum.Integrations);
    const { name } = useSelector((state) => selectConnectingProvider(state));
    const handleHideModal = () => {
        hideModal();
        dispatch(resetConnectingProviderId());
    };
    if (!name) {
        return;
    }
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, footer: _jsx(Button, { variant: 'primary', onClick: upgradeAccount, className: 'text-uppercase text-white', children: t('Upgrade.to.PRO_') }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(Image, { src: require(`../../integrations/images/provider-logo-${name}.png`), w: "50%", mt: "calc(var(--mantine-spacing-md) * 2.8)", mb: "lg", mx: "auto" }), _jsx("h4", { children: t('Account.upgrade_') }), _jsx("p", { children: t('infos:Provider.integration.upgrade.text_', { providerName: name }) })] }));
};
export default IntegrationsAccountUpgradeModal;
